import React, { useEffect, useState } from "react";
import { Layout } from "../../../components/Layout/Layout";
import { Button } from "../../../components/Button/Button";
import { TextInput } from "../../../components/TextInput/TextInput";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate, useParams } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { Controller } from "react-hook-form";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import TextField from "@mui/material/TextField";
import moment from "moment/moment";
import useFetchData from "../../../../src/hooks/FetchData";
import { http } from "../../../utils/httpCommon";
import LoadingCircle from "../../../components/LoadingCircle/LoadingCircle";

const vasTypes = [
  { name: "percent", id: 1 },
  { name: "flat", id: 2 },
];
const vasUnits = [
  { name: "multi", id: 1 },
  { name: "single", id: 2 },
];

const EditPromoCode = () => {
  const navigator = useNavigate();
  const { id } = useParams();
  const { response: promocode, loading: loadingPromocode } = useFetchData(`/promo-codes/${id}`);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showCountInput, setShowCountInput] = useState(false);

  const countValidation = () => {
    if (showCountInput) {
      return yup.number().required().typeError("Count is a required field").positive().integer();
    } else {
      return yup.string().notRequired();
    }
  };

  const schema = yup
    .object({
      name: yup
        .string()
        .required("Name is a required filed")
        .typeError("Name is a required filed "),
      code: yup
        .string()
        .required("Code is a required filed ")
        .typeError("Code is a required filed "),
      type: yup
        .string()
        .required("Promo Code type is a required filed ")
        .typeError("promo code type is a required filed "),
      value: yup
        .number()
        .required()
        .typeError("Promo Code Value is a required filed and it must be a positive number")
        .positive()
        .integer(),
      usage: yup
        .string()
        .required("Usage is a required filed")
        .typeError("Usage is a required filed"),
      count: countValidation(),
      startDate: yup
        .string()
        .required("Start Date is a required filed ")
        .typeError("Start Date is a required filed "),
      endDate: yup
        .string()
        .required("End Date is a required filed")
        .typeError("End Date is a required filed "),
      status: yup
        .string()
        .required("Status is a required filed")
        .typeError("Status is a required filed"),
    })
    .required();

  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      code: "",
      type: "",
      value: 0,
      usage: "",
      count: 0,
      status: "",
    },
  });

  const usageValue = useWatch({
    control,
    name: "usage",
  });

  useEffect(() => {
    if (usageValue === "multi") {
      setShowCountInput(true);
    } else {
      clearErrors("count");
      setShowCountInput(false);
    }
    countValidation();
  }, [usageValue, clearErrors]);

  const setCustomValue = (id, value) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  const onSubmit = async (passedData) => {
    try {
      setIsSubmitting(true);
      var sentData = JSON.stringify({
        name: passedData?.name,

        code: passedData?.code,
        usage: {
          type: passedData?.usage,
          count: parseInt(passedData?.count) ? parseInt(passedData?.count) : 1,
        },
        type: passedData?.type,
        value: parseInt(passedData?.value),
        startDate: moment(passedData?.startDate).format("YYYY-MM-DD"),
        endDate: moment(passedData?.endDate).format("YYYY-MM-DD"),
        status: passedData?.status,
      });

      await http.patch(`/promo-codes/${promocode?._id}`, sentData);

      toast.success("Client Edited successfully");
      navigator("/finance/promo-codes");
    } catch (error) {
      toast.error("An error occurred");
    } finally {
      setIsSubmitting(false);
    }
  };

  //   const startDate = watch("startDate");
  const handleChangeStartDate = (newValue) => {
    setCustomValue("startDate", newValue);
  };

  const handleChangeEndDate = (newValue) => {
    setCustomValue("endDate", newValue);
  };

  useEffect(() => {
    if (promocode) {
      reset({
        name: promocode?.name,
        code: promocode?.code,
        type: promocode?.type,
        value: promocode?.value,
        usage: promocode?.usage?.type,
        count: promocode?.usage?.count,
        startDate: moment(promocode?.startDate).format("MM/DD/YYYY"),
        endDate: moment(promocode?.endDate).format("MM/DD/YYYY"),
        status: promocode?.status,
      });
    }
  }, [promocode, reset]);

  return (
    <Layout>
      <Toaster />
      {loadingPromocode ? (
        <LoadingCircle />
      ) : (
        <div className="flex justify-start mx-10">
          <div className="w-[60%] bg-white p-4 ">
            <div className="flex flex-col">
              <div className="w-full  flex flex-col justify-between">
                <div className=" text-[26px]  my-2" style={{ fontFamily: "gilroy-bold" }}>
                  Edit Promo Code
                </div>
                <div className=" text-[18px]  " style={{ fontFamily: "gilroy-bold" }}>
                  About the promo code
                </div>
              </div>
            </div>
            {/* form  */}
            <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-4">
                <TextInput
                  label={"Name*"}
                  value={watch("name")}
                  {...register("name", { required: "input name is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Status</InputLabel>
                    <Controller
                      control={control}
                      name="status"
                      render={({ field: { onChange, onBlur, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="status"
                          id="status"
                          value={watch("status") || ""}
                          label="status"
                          inputRef={ref}
                        >
                          <MenuItem value=""> None </MenuItem>
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Stopped</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.status?.message}</p>
                </div>
              </div>

              <div className="mt-3">
                <TextInput
                  label={"Code*"}
                  value={watch("code") || ""}
                  {...register("code", { required: "Code  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.code?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Code Type</InputLabel>
                    <Controller
                      control={control}
                      name="type"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Code type"
                          inputRef={ref}
                          defaultValue={promocode?.type}
                        >
                          <MenuItem value=""> None </MenuItem>
                          {vasTypes?.map((type) => (
                            <MenuItem key={type.name} value={type.name}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.type?.message}</p>
                </div>
              </div>
              <div className="mt-3">
                <TextInput
                  type="number"
                  label={"Value*"}
                  {...register("value", { required: "Value  is required" })}
                />
                <p className="text-red-500 text-[16px]">{errors.value?.message}</p>
              </div>
              <div className="mt-3">
                <div>
                  <FormControl fullWidth sx={{ border: "none !important" }}>
                    <InputLabel>Usage</InputLabel>
                    <Controller
                      control={control}
                      name="usage"
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Select
                          sx={{
                            color: "black",
                            ".MuiOutlinedInput-notchedOutline": {
                              borderColor: "#EFEFEF",
                            },
                          }}
                          onBlur={onBlur}
                          onChange={onChange}
                          variant="outlined"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="Usage"
                          inputRef={ref}
                          defaultValue=""
                        >
                          <MenuItem value=""> None </MenuItem>
                          {vasUnits?.map((unit) => (
                            <MenuItem key={unit.name} value={unit.name}>
                              {unit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </FormControl>
                  <p className="text-red-500 text-[16px]">{errors.usage?.message}</p>
                </div>
              </div>

              <div>
                {showCountInput && (
                  <div className="mt-3">
                    <TextInput label={"Count*"} type="number" {...register("count")} />
                    <p className="text-red-500 text-[16px]">{errors.count?.message}</p>
                  </div>
                )}
              </div>
              {/* Dates */}
              <div className="flex  gap-4">
                <div className="mt-3 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="Start Date"
                      inputFormat="MM/DD/YYYY"
                      value={watch("startDate")}
                      onChange={handleChangeStartDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "#EFEFEF",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                borderColor: "#EFEFEF !important",
                              },
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#207beb !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              color: "#000000cc !important",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p className="text-red-500 text-[16px]">{errors.startDate?.message}</p>
                </div>
                <div className="mt-3 w-full">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                      label="End Date"
                      inputFormat="MM/DD/YYYY"
                      value={watch("endDate")}
                      onChange={handleChangeEndDate}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            width: "100%",
                            color: "#000000cc",
                            "& .MuiFormControl-root ": {
                              borderColor: "#EFEFEF",
                            },
                            "& .MuiInputBase-root": {
                              "& fieldset": {
                                borderColor: "#EFEFEF !important",
                              },
                              "&:hover fieldset": {
                                borderColor: "black !important",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#207beb !important",
                              },
                            },
                            "& .MuiFormLabel-root ": {
                              color: "#000000cc !important",
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <p className="text-red-500 text-[16px]">{errors.endDate?.message}</p>
                </div>
              </div>
              <div className="mt-6 mb-10 gap-[50px] flex justify-end">
                <Button
                  onClick={() => navigator("/finance/promo-codes")}
                  secondary={true}
                  disabled={isSubmitting}
                  invert={true}
                >
                  <span className="text-[16px]">Cancel</span>
                </Button>
                <Button loading={isSubmitting} secondary={true} type="submit">
                  <span className="text-[16px]">Save</span>
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default EditPromoCode;
