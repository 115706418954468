import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { http } from "../../../utils/httpCommon";
import { debounce } from "lodash";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { SelectedLocationContext } from "../../../Context/LocationProvider";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import moment from "moment";
import { useAuthUser } from "react-auth-kit";

const PromoCodes = () => {
  const navigator = useNavigate();
  const { selectedLocation } = useContext(SelectedLocationContext);
  const auth = useAuthUser();
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(
    async (filterModel) => {
      try {
        let searchVal = filterModel?.items[0]?.value;

        if (searchVal?.length > 0) {
          setPageState((old) => ({
            ...old,
            isLoading: true,
          }));

          const res = await http.get(
            `/transactions?page=${pageState.page}&limit=${pageState.pageSize}&location=${selectedLocation}&search=${searchQuery}`,

            JSON.stringify({
              page: pageState.page,
              limit: pageState.pageSize,
              name: searchVal,
            }),
          );

          const rowsData = res.data?.data?.map((d) => ({
            id: d._id,
            ...d,
          }));

          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: rowsData,
            //get from another api
            total: res?.data?.metadata?.[0]?.count,
          }));
        } else {
          debounced();
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response?.data?.message || error.message || "Something went wrong");
      }
    },
    [pageState.page, pageState.pageSize, selectedLocation],
  );

  const columns = [
    {
      field: "name",
      headerName: "Code Name",
      width: 180,
      filterable: false,
      renderCell: (params) => {
        return (
          <Link
            to={`/finance/promo-code/${params.row?._id}`}
            className="flex flex-col gap-[5px] h-[80px] text-[16px]  justify-center hover:text-[#34B2C0]"
          >
            <div className="font-semibold">{params.row?.source?.name}</div>
            <div className="flex items-center">{params.row?.name}</div>
          </Link>
        );
      },
    },
    {
      field: "type",
      headerName: "Code type",
      width: 150,
      renderCell: (params) => {
        return <div className="flex gap-[10px] items-center text-[16px] ">{params?.row?.type}</div>;
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      minWidth: 150,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {moment(params?.row?.createdAt).format("MMM Do YYYY")}
          </div>
        );
      },
    },
    {
      field: "utilization",
      headerName: "Utilization",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center text-[16px]">
            {params?.row?.utilization?.map((u) => u?.count)}
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center w-fit text-[16px]">
            {params?.row?.status === "active" ? (
              <div className=" text-[#00AD3B] bg-[#00ad3b1a]  font-semibold p-3 flex  justify-center items-center rounded-[25px]  ">
                Active
              </div>
            ) : params?.row?.status === "pending" ? (
              <div className=" text-[#34B2C0] bg-[#D6F0F2]  font-semibold p-3 flex  justify-center items-center rounded-[25px]  ">
                Pending
              </div>
            ) : params?.row?.status === "expired" ? (
              <div className=" text-[#797979] bg-[#EFEFEF]  font-semibold p-3 flex  justify-center items-center rounded-[25px]  ">
                Expired
              </div>
            ) : (
              <div className=" text-[#797979]  bg-transparent border-[1px] border-[#E4E4E4]  font-semibold p-3 flex  justify-center items-center rounded-[25px]  ">
                Stopped
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      width: 180,
      renderCell: (params) => {
        return <div>{moment(params?.row?.startDate).format("L")}</div>;
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      width: 150,
      renderCell: (params) => {
        return <div>{moment(params?.row?.endDate).format("L")}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 180,
      renderCell: (params) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <button
                onClick={() => navigator(`/finance/promo-code/edit/${params.row?._id}`)}
                className="group text-[16px] text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
              >
                <div className="flex justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                  <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                </div>
                Edit
              </button>
            )}
          </>
        );
      },
    },
  ];

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));
      const res = await http.get(`/promo-codes?page=${pageState.page}&limit=${pageState.pageSize}`);
      const rowsData = res.data?.data?.docs?.map((d) => ({
        id: d._id,
        ...d,
      }));
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rowsData,
        total: res?.data?.data?.total,
      }));
    } catch (error) {
      toast.error(error.response?.data?.message || error.message || "Something went wrong");
    }
  };
  const debounced = useCallback(debounce(fetchData, 1000), [pageState]);

  useEffect(() => {
    fetchData();
  }, [pageState.page, pageState.pageSize, selectedLocation, searchQuery]);

  const handleActionButton = () => {
    navigator("/finance/promo-codes/new");
  };

  return (
    <div>
      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState?.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        actionButton={"Create promo code "}
        onFilterChange={onFilterChange}
        handleActionButton={handleActionButton}
      />
    </div>
  );
};

export default PromoCodes;
