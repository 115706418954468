import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/Button";
import { http } from "../../utils/httpCommon";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ModalComponent from "../../components/Modal/Modal2";
import { toast } from "react-hot-toast";
import { TextInput } from "../../components/TextInput/TextInput";
import { useNavigate } from "react-router-dom";

const EditCurrencyModal = ({ opened, setOpened, data, fetchData }) => {

  const schema = yup
    .object({
      name: yup.string().required('Name is required'),
      code: yup.string().required("Code is required"),
      Symbol: yup.string().optional().default(""),
      exchangeRate: yup.number().required("Exchange rate is required"),
    })
    .required();


  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const navigator = useNavigate();

  const [submitLoading, setSubmitLoading] = useState(false);
  const onSubmit = async (passedData) => {
    setSubmitLoading(true);
    try {
      await http.put(
        `/currencies/${data._id}`,
        JSON.stringify({
          name: passedData.name,
          code: passedData.code,
          symbol: passedData.symbol,
          exchangeRate: passedData.exchangeRate
        }),
      );
      setSubmitLoading(false);
      handleOnClose();
      fetchData();
      navigator("/currencies");
      // onNotesUpdate(passedData.notes);
      toast.success("Currency updated successfully");
    } catch (error) {
      setSubmitLoading(false);
      console.log(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const handleOnClose = () => {
    setOpened(false);
    reset();
  };

  useEffect(() => {
    reset({
      name: data.name,
      code: data.code,
      symbol: data.symbol,
      exchangeRate: data.exchangeRate
    });
  }, [data]);

  return (
    <ModalComponent opened={opened} setOpened={setOpened} onClose={handleOnClose}>
      <div className="flex flex-col">
        <div className="text-[24px] font-bold">Edit "{data.name}"</div>

        <form className="w-full " onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <TextInput {...register("name")} label={"Name"} />
            <p className="text-red-500 text-[16px]">{errors.name?.message}</p>
          </div>

          <div className="mt-4 ">
            <TextInput {...register("code")} label={"Code"} />
            <p className="text-red-500 text-[16px]">{errors.code?.message}</p>
          </div>

          <div className="mt-4 ">
            <TextInput {...register("symbol")} label={"Symbol"} />
            <p className="text-red-500 text-[16px]">{errors.code?.symbol}</p>
          </div>

          <div className="mt-4">
            <TextInput
              {...register("exchangeRate")}
              label="Exchange Rate"
              type="number"
              step="0.01"
              presicion={2}
            />
            {errors.exchangeRate?.message && (
              <p className="text-red-500 text-[16px]">
                {errors.exchangeRate.message}
              </p>
            )}
          </div>



          <div className="mt-6 mb-8 flex justify-end gap-10">
            <Button onClick={() => setOpened(false)} secondary={true} invert={true}>
              Cancel
            </Button>
            <Button loading={submitLoading} type="submit" secondary={true}>
              Confirm
            </Button>
          </div>
        </form>
      </div>
    </ModalComponent>
  );
};

export default EditCurrencyModal;
