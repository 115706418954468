import React, { useState, useCallback, useEffect } from "react";
import { http } from "../../../utils/httpCommon";
import DeleteModal from "../../../components/DeleteModal/DeleteModal";
import NewAmenity from "./NewAmenity";
import EditAmenity from "./EditAmenity";
import whiteTrash from "../../../assets/icons/whiteTrash.png";
import whiteEdit from "../../../assets/icons/whiteEdit.png";
import Table from "../../../components/DataGrid/Table";
import { toast } from "react-hot-toast";
import { debounce } from "lodash";
import { useAuthUser } from "react-auth-kit";

const AmenitiesTable = () => {
  const [searchQuery, setSearchQuery] = React.useState("");

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
    debounced(event.target.value, 600);
  };

  ///onFilterChange
  const onFilterChange = useCallback(async (filterModel) => {
    try {
      let searchVal = filterModel?.items[0]?.value;

      if (searchVal?.length > 0) {
        setPageState((old) => ({
          ...old,
          isLoading: true,
        }));

        const res = await http.post(
          `/amenity/all`,
          JSON.stringify({
            page: pageState.page,
            limit: pageState.pageSize,
            name: searchVal,
          })
        );
        let filteredData = res.data.data?.data?.filter((item) => !item?.isDeleted);
        const rows = filteredData?.map((d) => ({
          id: d?._id,
          ...d,
        }));

        setPageState((old) => ({
          ...old,
          isLoading: false,
          data: rows,
          total: filteredData?.length,
        }));
      } else {
        debounced();
      }
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  }, []);

  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const fetchData = async (searchValues) => {
    try {
      setPageState((old) => ({
        ...old,
        isLoading: true,
      }));

      const res = await http.post(
        `/amenity/all`,
        JSON.stringify({
          page: pageState.page,
          limit: pageState.pageSize,
          name: searchValues,
        })
      );
      let filteredData = res.data.data?.data?.filter((item) => !item?.isDeleted);
      const rows = filteredData?.map((d) => ({
        id: d?._id,
        ...d,
      }));

      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: rows,
        total: filteredData?.length,
      }));
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data.message || error.message || "Something went wrong");
    }
  };

  const debounced = debounce(fetchData, 1000);
  const auth = useAuthUser();
  const actionRoles = ['Super Admin', 'Area Manager'];
  useEffect(() => {
    fetchData(searchQuery);
  }, [pageState.page, pageState.pageSize]);

  const columns = [
    {
      field: "Image",
      headerName: "",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center py-2">
            {params?.row?.icon ? (
              <img
                className="w-[66px] object-cover h-[66px] rounded-[50px]"
                src={params?.row?.icon}
                alt={params?.row?.icon}
              />
            ) : (
              <span className="h-[66px]"></span>
            )}
          </div>
        );
      },
    },

    {
      field: "name",
      headerName: "Name",
      width: 450,
      renderCell: (params) => {
        return (
          <div className="flex gap-[10px] items-center ml-4  ">
            <span className=" font-semibold text-[16px]   ">{params.row.name}</span>
          </div>
        );
      },
    },

    actionRoles.includes(auth().role) && {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      width: 250,
      renderCell: (params) => {
        return (
          <>
            {auth().role !== "Land Lord" && (
              <div className="flex flex-col gap-[10px]">
                <button
                  onClick={() => {
                    handelEdit(params?.row?.id);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex   justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteEdit} alt="edit" />
                  </div>
                  Edit
                </button>
                <button
                  onClick={() => {
                    handleDelete(params?.row);
                  }}
                  className="group text-[#686868] flex items-center hover:text-[#32B3BE] transition-all "
                >
                  <div className="flex   justify-center items-center bg-[#686868] w-[30px] h-[30px] transition-all rounded-[10px] mr-2 group-hover:bg-[#32B3BE]">
                    <img className=" w-[18px] " src={whiteTrash} alt="edit" />
                  </div>
                  Delete
                </button>
              </div>
            )}
          </>
        );
      },
    },

  ];

  // handle edit
  const [editId, setEditId] = useState("");
  const [openEditModal, setOpedEditModal] = useState();
  const handelEdit = async (id) => {
    try {
      const res = await http.get(`/amenity/${id}`);
      setEditId(res.data.data);
      setOpedEditModal(true);
    } catch { }
  };

  //handle delete
  const [openedDelete, setOpenedDelete] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const handleDelete = async (item) => {
    setDeletedItem(item);
    setOpenedDelete(true);
  };

  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleSubmitDelete = async () => {
    setDeleteLoading(true);
    try {
      await http.delete(`/amenity/${deletedItem?.id}`);
      setDeleteLoading(false);
      setOpenedDelete(false);
      toast.success("Amenity has been deleted successfully");
      fetchData();
    } catch (error) { }
  };

  //handle add new amenity
  const [openAddModal, setOpenAddModal] = useState(false);
  const handleActionButton = () => {
    setOpenAddModal(true);
  };

  return (
    <>
      <DeleteModal
        opened={openedDelete}
        setOpened={setOpenedDelete}
        deletedItem={deletedItem}
        loading={deleteLoading}
        handleSubmitDelete={handleSubmitDelete}
        deleteMessage="Please note that you will be deleting this amenity from facility
        listings"
      />

      <NewAmenity opened={openAddModal} setOpened={setOpenAddModal} fetchData={fetchData} />

      <EditAmenity
        opened={openEditModal}
        setOpened={setOpedEditModal}
        id={editId}
        fetchData={fetchData}
      />

      <Table
        searchQuery={searchQuery}
        handleChange={handleChange}
        rowCount={pageState.total}
        isLoading={pageState.isLoading}
        data={pageState.data}
        page={pageState.page - 1}
        pageSize={pageState.pageSize}
        onPageChange={(newPage) => setPageState((old) => ({ ...old, page: newPage + 1 }))}
        onPageSizeChange={(newPageSize) =>
          setPageState((old) => ({ ...old, pageSize: newPageSize }))
        }
        columns={columns}
        fetchData={fetchData}
        title={"Amenities"}
        actionButton={"Add Amenity"}
        handleActionButton={handleActionButton}
        onFilterChange={onFilterChange}
        removeActionButton={!actionRoles.includes(auth().role)}
      />
    </>
  );
};

export default AmenitiesTable;
